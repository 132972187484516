import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// Golbal Components
import MandatoryIcon from "./components/MandatoryIcon.vue";
import AlertBox from "./components/AlertBox.vue";
import CardLoader from "./components/CardLoader.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import GoBack from "@/components/GoBack.vue";
import ConfirmBox from "@/components/ConfirmBox.vue";
import MessageBox from "@/components/MessageBox.vue";
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";
import PrintPreview from "@/components/PrintPreview.vue";
// axios.defaults.baseURL = "http://192.168.18.51:8180/mychef/HO/src/php/";
axios.defaults.baseURL = "https://mychef.kvrtechnologies.xyz/php/";



const app = createApp(App);
app.use(store);
app.use(router);
app.use(Vue3SimpleHtml2pdf);

app.component("MandatoryIcon", MandatoryIcon);
app.component("AlertBox", AlertBox);
app.component("CardLoader", CardLoader);
app.component("CloseBtn", CloseBtn);
app.component("GoBack", GoBack);
app.component("ConfirmBox", ConfirmBox);
app.component("MessageBox", MessageBox);
app.component("PrintPreview", PrintPreview);

app.mount("#app");
//createApp(App).use(store).use(router).mount('#app')
