import { createStore } from 'vuex'

export default createStore({
  state: {
    isAuthenticated: false,
    userId: "",
    userName: "",
    ImagePath: "/menuimg",
    OutletId: localStorage.getItem("OutletId"),
    OutletName: localStorage.getItem("OutletName"),
    tradeMark: localStorage.getItem("TradeMark"),
    IsAdmin: localStorage.getItem("IsAdmin"),
    // ImagePath: "F:\\xampp\\htdocs\\ASRHAVELI\\HO\src\\\php\\menuimg"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
